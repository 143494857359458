<template>
  <div class="vx-row match-height">
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openManageBranches()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/branches.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Manage Branches</h5>
        </div>
        <p class="text-grey mt-4"></p>
      </vx-card>
    </div>
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openManageDeliveryFees()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/delivery.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Manage Delivery Fees</h5>
        </div>
        <p class="text-grey mt-4"></p>
      </vx-card>
    </div>

    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openManageCurrencies()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/currencies.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Manage Currencies</h5>
        </div>
        <p class="text-grey mt-4"></p>
      </vx-card>
    </div>

    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openManageExchangeRates()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/exchange.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Manage Exchange Rates</h5>
        </div>
        <p class="text-grey mt-4"></p>
      </vx-card>
    </div>
    
  </div>
  
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import Vue from "vue"
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

export default {
  components: {
    AgGridVue,
  },
  props: {
    applicationId: { required: true }
  },
  data () {
    return {
    }
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.$route.params.applicationId)) ) {
      this.$router.push('/error-404')
    }
   
  },
  mounted() {
  },
  
  methods: {
    openManageBranches () {
      this.$router.push({name: 'application-currency-exchange-branches',
        params: { applicationId: this.applicationId }
      })
    },
    openManageDeliveryFees () {
      this.$router.push({name: 'application-currency-exchange-delivery-fees',
        params: { applicationId: this.applicationId }
      })
    },
    openManageCurrencies () {
      this.$router.push({name: 'application-currency-exchange-currencies',
        params: { applicationId: this.applicationId }
      })
    },
    openManageExchangeRates () {
      this.$router.push({name: 'application-currency-exchange-rate-manager',
        params: { applicationId: this.applicationId }
      })
    },
    setAltImgForThumbnail (event) {
      const altText = encodeURIComponent('Exchange Rate')
      event.target.src = `https://dummyimage.com/1280X720/009687/ffffff.png&text=${altText}`
    },
  }
}
</script>

<style>

</style>
